.q-page-container {
  position: fixed;
  inset: 0px;
  overflow-y: scroll;
}
.option-button {
  position: relative;
  pointer-events: none;
  opacity: 1;
}
.option-button .q-btn {
  position: absolute;
  bottom: 50%;
  pointer-events: all;
}
.q-tabs >>> .q-tabs__content {
  overflow: visible !important;
}
.q-footer--hidden .option-button .q-btn {
  display: none;
}
@media (max-width: 599px) {
  .q-tab {
    padding: 0 12px;
  }
}
/*# sourceMappingURL=src/layouts/basic/mobilleo/index.css.map */